import React from 'react';
import PropTypes from 'prop-types';

import styles from './PublicBenefitsList.module.scss';

const PublicBenefitsList = ({ children }) => (
  <div className={styles.container}>
    <ul className={styles.list}>{children}</ul>
  </div>
);

PublicBenefitsList.propTypes = {
  children: PropTypes.node,
};

export default PublicBenefitsList;
