import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import Button from '../../Button';
import { linkResolver, contentSerializer } from '../../../utils';

import styles from './PublicBenefit.module.scss';

const PublicBenefit = ({ title, description, link, linkLabel, overline }) => (
  <li className={styles.container}>
    <div className={styles.content}>
      <p className={styles.overline}>{overline}</p>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>
        <RichText render={description} htmlSerializer={contentSerializer} />
      </p>
      {link && linkLabel ? (
        <div className={styles.button}>
          <Button
            text={linkLabel}
            url={linkResolver(link)}
            external={link._linkType === 'Link.web'}
          />
        </div>
      ) : null}
    </div>
  </li>
);

PublicBenefit.propTypes = {
  overline: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
};

export default PublicBenefit;
