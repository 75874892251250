import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../../components/SEO';
import Wrapper from '../../components/Wrapper';
import PublicBenefit from '../../components/DevensCampusPage/PublicBenefit';
import PublicBenefitsList from '../../components/DevensCampusPage/PublicBenefitsList';
import Footer from '../../components/Footer';
import Header from '../../components/BasicPage/Header';
import Body from '../../components/BasicPage/Body';
import ImageGrid from '../../components/ImageGrid';
import NotFoundPage from '../../pages/404';

const isContentEmpty = content => {
  if (!content || !content.length) {
    return true;
  }

  return content.length === 1 && !content.some(item => item.text);
};

const DevensPublicBenefitsPage = ({ location, data }) => {
  const pageData = data.prismic.allDevens_public_benefits_pages.edges.slice(0, 1).pop();

  if (!pageData || !pageData.node) {
    return <NotFoundPage />;
  }

  const {
    photos,
    photos_two,
    content,
    content_two,
    content_three,
    section,
    title,
    public_benefits: publicBenefitsRaw,
    featured_image,
    featured_imageSharp,
    featured_embed_video,
    seo,
    secondary_topper_cta_link,
    secondary_topper_cta_label,
  } = pageData.node;
  const titleRaw = RichText.asText(title);

  const gridContentPhotos = photos ? photos.slice(0, 1).pop() : [];
  const gridContentPhotosTwo = photos_two ? photos_two.slice(0, 1).pop() : [];

  const publicBenefits = publicBenefitsRaw
    .filter(pb => pb)
    .map(pb => ({
      title: pb.title,
      link: pb.link,
      linkLabel: pb.link_label,
      description: pb.description,
    }));

  return (
    <>
      <SEO
        title={titleRaw}
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      <Header
        title={title}
        image={featured_image}
        sharpImage={featured_imageSharp}
        embedVideo={featured_embed_video}
        subnav={section ? section.navigation : null}
        overline={section.section_title}
        secondaryCtaLink={secondary_topper_cta_link}
        secondaryCtaLabel={secondary_topper_cta_label}
      />

      {!isContentEmpty(content) ? <Body content={content} dropcap={false} /> : null}

      {gridContentPhotos && (gridContentPhotos.feature_photo || gridContentPhotos.feature_video) ? (
        <Wrapper>
          <ImageGrid photos={gridContentPhotos} />
        </Wrapper>
      ) : null}

      <Wrapper>
        <PublicBenefitsList>
          {publicBenefits.map((pb, index) => (
            <PublicBenefit key={pb.title} overline={`0${index + 1}`} {...pb} />
          ))}
        </PublicBenefitsList>
      </Wrapper>

      {!isContentEmpty(content_two) ? <Body content={content_two} dropcap={false} /> : null}

      {gridContentPhotosTwo &&
      (gridContentPhotosTwo.feature_photo || gridContentPhotosTwo.feature_video) ? (
        <Wrapper>
          <ImageGrid photos={gridContentPhotosTwo} />
        </Wrapper>
      ) : null}

      {!isContentEmpty(content_three) ? <Body content={content_three} dropcap={false} /> : null}

      <Footer />
    </>
  );
};

DevensPublicBenefitsPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allDevens_public_benefits_pages: PropTypes.object,
    }),
  }),
};

export const featurePhotoFragment = graphql`
  fragment FeaturePhotoQuery on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query DevensPublicBenefitsQuery {
    prismic {
      allDevens_public_benefits_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            section {
              ... on PRISMIC_Site_section {
                section_title
                navigation {
                  label
                  link {
                    ...LinkQuery
                  }
                }
              }
            }

            title
            secondary_topper_cta_link {
              ...LinkQuery
            }
            secondary_topper_cta_label
            featured_image
            featured_imageSharp {
              ...FeaturePhotoQuery
            }
            featured_embed_video
            public_benefits {
              title: title1
              description
              link {
                ...LinkQuery
              }
              link_label
            }
            photos {
              feature_photo
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              feature_video {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                }
              }
              thumbnail_one
              thumbnail_oneSharp {
                ...ThumbnailQuery
              }
              thumbnail_two
              thumbnail_twoSharp {
                ...ThumbnailQuery
              }
            }

            content

            photos_two {
              feature_photo
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              feature_video {
                _linkType
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  name
                }
              }
              thumbnail_one
              thumbnail_oneSharp {
                ...ThumbnailQuery
              }
              thumbnail_two
              thumbnail_twoSharp {
                ...ThumbnailQuery
              }
            }

            content_two

            content_three
          }
        }
      }
    }
  }
`;

export default DevensPublicBenefitsPage;
